.row{
    top:22%;
}
.img-logo{
    height: 230px;
    width: 230px;
}
.content{
    align-items: center;
    justify-content: center;
    text-align: start;

}
.content a{
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    color: #333333;
}
.content i{
    font-size: 1rem;
    margin-right:1rem;
    color: #3385ff; 

}
.footer_addr{
    text-align: start;
}
.content i:hover{
    font-size: 1.1rem;
    color: 	 #005ce6;    

}
.india_mart a{
    color: #0d6efd;
    text-decoration: underline;
}

@media screen and (max-width:850px){
   /* .left_container{
    height: 150px;
    width: 150px;
   } */
   .left_container{
    left: 50%;
   }
   
   .left_container{
    height: 70%;
    width: 70%;
    margin-left: 2rem;
   }
   .left_container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    
   }
  .content h4{
    text-align: center;
  } 
  
   .content h3{
    margin: 1rem 0 01rem;
    text-align: center;
   }
   .right{
    margin: 0;
    background-color:#f2f2f2
   }
   hr{
    display: contents;
   }
}

