.hero{
    width:100%;
    height:75vh;
    position: relative;   
}

.hero-contact{
    width: 100%;
    height: 45vh;
    position: relative; 
    object-fit: cover;
}

.hero-mid h1{
    padding: 4rem !important;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.95;
}
.hero-text{
    position: absolute;
    top:55%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.hero-text h1{
    font-size: 2rem;
    font-weight: 800;
    background-color: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p{
    font-size:1.3rem;
    font-weight: 800;
    color: white;
    padding: 0.5rem,0.2rem;   
}
@media screen and (max-width:850px) {
    .hero-text h3{
        padding: 10px 20px;
    }
    .hero-text p{
     font-size: 1.1rem;
     padding: 0 0 2rem 0; 
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.95;
    }
   
}