.subService{
    margin:7rem 6rem;
    color:black;
}
.subService h1{
    font-size: 3rem;
    font-weight: 600;
}
.subService_card{
    margin: 7rem;
    display: flex;
    justify-content: space-between;
}
.p-card{
    width: 30%;
    text-align: start;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0,0.11);
    border-radius: 7px;
    cursor:pointer;
    padding: 1rem 0.8rem;
}
.prod_image{
height: 200px;
overflow: hidden;
border-radius: 7px;
}
.prod_image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}
.prod_image:hover img{
    transform: scale(1.3);
}
.p-card h4{
    font-size: 1.3rem;
    padding: 0.7rem;
    text-align: center;
}
.p-card button{
     display: inline;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius:0.3rem;
    font-size:1rem;
    font-weight:600;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin-left: 40%;
    text-decoration: none;
    background-color: white;
    
    
}
.p-card a{
    text-decoration: none;
}
.p-card button:hover{
    background-color: black;
    color: white;
}
/* new start */
@media screen and (max-width:850px) {
 .subService{
    margin: 5rem 2rem;
 } 
 .subService h1{
    font-size: 2rem;
    font-weight: 600;
}
 .subService_card{
    margin: 3rem;
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
}  
.p-card{
    width: 100%;
    margin-bottom: 1.5rem;
}
}