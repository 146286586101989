body{
    margin: 0;
    padding: 0;
}
.gallery{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap ;
    margin: 15px 0;
    margin: 8%;
    
}
.gallery img{
    flex-wrap: wrap;
    width: 150px;
    height: 120px;
    margin: 10px 0;
    border: 10px solid white;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.25);
    object-fit:contain;
    transition: 0.3s ease-in-out;
}
.gallery img:hover{
    transform: scale(1.3);

}

.carousel-item img{
    height: 100px;
    width: 100px;
    object-fit: cover;
}
@media screen and (max-width:850px) {
    .gallery img{
        flex-wrap: nowrap;
        width: 100px;
        height: 100px;
        margin: 10px 0;
        border: 10px solid white;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.25);
        object-fit:contain;
        transition: 0.3s ease-in-out;
    }
    .subproduct h1{
        font-size: 2rem;
        font-weight: 600;
    }
}