 /* .navbar{
    height: 10vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
 } */
 /*
.navbar-logo{
    color: wheat;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
}
.nav-items{
display: flex;
list-style: none;
text-align: center;
text-align: center;
width: 70vw;
justify-content: flex-end;
margin-right: 2rem;
} */
 /* .nav-links{
    display: flex;
    align-items: center;
    height: 10vh;
} */
 /* .nav-links a{
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    margin-right: 2rem;
    padding: 6px 16px;
}
.nav-links a:hover{
    background-color: black;
    color: white;
} 
/* old vi */

 .NavbarItems {
     position: fixed;
     top: 20px;
     left: 50%;
     transform: translate(-50%);
     z-index: 9999;
     background-color: white;
     width: 100%;
     padding: 0 30px;
     box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
     width: 90%;
     border-radius: 13px;
     text-align: start;
 }


 .NavbarItems a {
     text-decoration: none;
 }

 .navbar-logo {
     height: 4.5rem;
     font-size: 3rem;
     cursor: pointer;
     background-color: white;
     border-radius: 2px;
     padding: 2px;

 }

 .nav-menu {
     display: grid;
     grid-template-columns: repeat(6, auto);
     grid-gap: 10px;
     list-style: none;
     align-items: center;
 }

 .nav-links {
     text-decoration: none;
     color: black;
     border-radius: 10px;
     font-size: 1.2rem;
     font-weight: 600;
     padding: 0.7rem 1rem;
     white-space: nowrap;
 }

 .dropdown-item.active {
     background-color: white;
     color: #808080;
 }


 /* mega */
 .first_menu {
     margin: 10px;
     background-color: aqua;
 }

 .dropdown-menu {
     border-radius: 0;
     border: 0;
     padding: .5em;
     box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);

 }

 /* .dropdown:hover .dropdown-menu {
    display: flex;
} */
 .dropdown-menu ul {
     list-style: none;
     padding: 0;
 }

 .dropdown-menu.show {
     display: flex;
 }

 .dropdown-menu li a {
     color: #595959;
     padding: .5em 1em;
 }

 .dropdown-menu li a:hover {
     color: black;
 }

 .dropdown-menu li:first-child a {
     font-weight: bold;
     font-size: 15px;
     color: black;
     margin-top: 10px;
 }

 @media screen and (min-width:993px) {
     .dropdown:hover .dropdown-menu {
         display: flex;

     }

     .dropdown-menu.show {
         display: flex;
     }


 }

 @media screen and (max-width:992px) {

     .dropdown-menu.show {
         max-height: 400px;
         overflow-y: scroll;
         display: block;

     }

     .serv {

         width: 250px;
         word-wrap: break-word;
     }

     .dropdown-item {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
     }

 }






 .nav-links i {
     padding-right: 10px;
 }

 .nav-links:hover {
     background-color: #6666ff;
     color: white;
     border-radius: 4px;
     transition: all 0.2s ease-in-out;
 }

 .nav-links-mobile {
     display: none;
 }

 button {
     padding: 0.5rem 1rem;
     white-space: nowrap;
     border-radius: 0.3rem;
     font-size: 1rem;
     font-weight: 600;
     border: none;
     cursor: pointer;
     transition: 0.2s ease-in-out;
 }

 .menu-icons {
     display: none;
 }

 @media screen and (max-width:850px) {

     /* .NavbarItems{
        z-index: 99;
        width: 80%;
    } */
     .navbar-toggler {
         text-decoration: none;
         color: white;
         border: none;
     }

     .NavbarItems {
         position: fixed;
         top: 20px;
         left: 50%;
         transform: translate(-50%);
         z-index: 9999;
         background-color: white;
         width: 100%;
         padding: 0 30px;
         box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
         width: 90%;
         border-radius: 13px;
         z-index: 99;
     }

     .nav-menu {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         background-color: white;
         width: 100%;
         height: auto;
         position: absolute;
         top: 0;
         left: -100%;
         opacity: 0;
         align-items: stretch;
         padding: 80px 0 30px 0;
         margin: 0;
         transition: 0.3s ease-in-out;
         border-radius: 13px;
     }

     .navbar-logo {
         height: 60px;
         width: 60px;
     }

     .nav-menu.active {
         left: 0;
         opacity: 1;
         z-index: -1;
         transition: 0.3s ease-in-out;
     }

     .nav-links {
         display: block;
         width: 100%;
         font-size: 1.2rem;
         padding: 2rem 0;
     }

     .nav-links:hover {
         background-color: #6666ff;
         transition: none;
         color: white;
     }

     .nav-links-mobile {
         display: block;
         text-align: center;
         padding: 1.2rem;
         margin: auto;
         border-radius: 4px;
         width: 80%;
         /* background-color: #01959a; */
         text-decoration: none;
         color: white;
         font-size: 1.2rem;
         font-weight: 700;
         letter-spacing: 2px;
     }

     .menu-icons {
         display: block;
         cursor: pointer;
     }

     .menu-icons i {
         font-size: 1.2rem;
         color: black
     }

 }