.card_prod_name h1{
  font-weight: 600;
margin: 5rem;
}
.card-body h5{
  font-weight: 600;
}
.card-body a{
  font-size: 1rem;
  color: grey;
  text-decoration: none;
}
.card-body a:hover{
  color: black;
  font-weight: 600;
}
.slider{
  margin-left: 5rem;
  margin-right: 5rem;
}
.slick-slide > div {
  margin: 0 15px 10px 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0,0.11);
  border-radius: 7px;
  border: 1px solid  #cccccc;
}


.img_div img{
  height: 300px;
  width: 100%;
  object-fit: contain;
} 


@media screen and (max-width:850px) {
  
  .card_prod_name h1{
    font-weight: 600;
  margin: 4rem;
  }
  .slick-slide > div {
    margin: 0 10px 20px 10px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0,0.11);
    border-radius: 7px;
    border: 1px solid  #cccccc;
  }
  .card{
    height:300px;
    width: 500px;
  }
  .img_div,.card-title{
    margin-top:-40px;
  }
.img_div img{
  height: 300px;
  width: 100%;
  object-fit: contain;
} 
.card-body h5{
  font-weight: 800;
  font-size:14px;
}
.card-body a{
  font-size: 0.9rem;
  color: grey;
  text-decoration: none;
}
.slider{
  margin-left: 2rem;
  margin-right: 2rem;
} 
}

@media screen and (max-width:500px) {
  .card{
    height:390px;
    width: 500px;
  }
  .img_div,.card-title{
    margin-top:0;
  }

}








