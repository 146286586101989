/* career */
.icon{
    font-size: 5rem;
    background-color: black;
    border-radius: 50%;
    color: #E4A11B;
    margin-bottom: 10px;
}
.hire_tag{
   margin-top: 7%;
}
.hire_tag h1{
    background-color: black;
    color: #E4A11B;
}


 @media screen and (max-width:700px){
   
.hire_tag {
    margin-top:30%;
}

.wrap_div{
    height: 245px;
    width: 270px;
    background-color: #f2f2f2;
    padding: 20px;
} 
.wrap_div{
    margin: 3% 6% 3% 8%;

}
  }
 

