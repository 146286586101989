/* body{
     width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center; 
} */


.row{
    width: 80%;
    max-width: 1170px;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:50px 30px;
    /* margin-top: 18%;
    margin-bottom: 10%;
    margin-left: 5%; */
    margin: 10%;
}
.left{
    overflow: hidden;
    height: 400px;
    width: 600px;
}
.left img{
    /* width: 100%;
    height: 100%; */
    object-fit: contain;
}
.row.right{
    display:flex;
    align-items: center;
    padding: 10px;   
}
.row.right.content{
    padding-left: 20px;
}
.row.right.content p{
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 15px;
}
.content h4{
    font-size: 30px;
    font-weight: 600;
}

@media screen and (max-width:991px) {
    .row.right.content{
        padding-left: 0;
    }
}

@media screen and (max-width:850px) {
    .row{
        width: 80%;
        grid-template-columns:1fr;
        margin-top: 22%;
        grid-gap:20px 20px;

    }
    .row.right.content{
        padding-left: 0;
    }
 
.left{
    overflow: hidden;
    height: 200px;
    width: 270px;
}
.left img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 10px;
}
.row.right{
    display:flex;
    align-items: center;
    padding: 10px;  
}
.content span{
    margin: 2rem;
}
    
}
