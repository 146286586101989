.footer{
    padding:2rem 10rem;
    background-color: #454545;
    color: white;
}
.footer-logo{
background-color: white;
padding: 5px;
}
.top{
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top i{
    color: white;
    font-size: 2rem;
    margin-left: 1rem;    
}
.top i:hover{
    color:  #8080ff;;
}
.bottom{
padding-top: 2rem;
text-align: start;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
}
.bottom i{
    padding:5px;
}
.bottom img{
    height:7rem;
    width: 7rem;
}
.bottom div{
    display: flex;
    flex-direction: column;
}
.bottom h5{
    font-size: 1.3rem;
    padding: 1.2rem 0 0.8rem 0;
}
.bottom a{
    text-decoration: none;
    color: white;
    padding-bottom: 0.4rem;
    font-size: 1rem;
}
.bottom a:hover{
    color: 	 #8080ff;
}
iframe{
    height: 300px;
}
.indiamart a{
text-decoration: underline;
color:	 #8080ff;
}
.copywrite{
    background-color: black;
    color: white;
}
@media screen and (max-width:850px) {

    .footer{
        padding:2rem 2rem;
        background-color: #808080;
        color: white;
        /* max-width: 360px; */
        text-align: center;
        justify-content: center;
    }
    .footer i{
        margin: 1rem 1rem 0 0;
    }
    .top{
        display: grid;

    }
    .bottom div{
        width: 100%;
    }
    .bottom_prod_list h4{
        margin-top: 2rem;
        font-weight: 400;
    }
    .address{
        margin-top: 2rem;
    }
    
    iframe{
        height: 250px;
        width: 270px;
    }
    .bottom{
        display:grid;
        margin-top: 30px;

    }

    .location{
        margin-top: 2rem;
    
    }
    
}